import React from "react";
import PropTypes from "prop-types";

// Utilities
import kebabCase from "lodash/kebabCase";
import styled from 'styled-components';

// Components
import { Link, graphql } from "gatsby";
import Layout from '../components/layout';
import SEO from "../components/seo";

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <Layout>
    <SEO title="All tags" />
    <div style={{marginBottom: '32px'}}>
      <h1 style={{marginBottom: '16px'}}>Tags</h1>
      {group.map(tag => (
        <TagLink key={tag.fieldValue}>
          <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
            {tag.fieldValue} ({tag.totalCount})
          </Link>
        </TagLink>
      ))}
    </div>
  </Layout>
);

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;


const TagLink = styled.div`
  margin: 4px 0px;
  > a {
    transition: color 0.15s ease-out;
    color: rgba(27, 28, 37, 0.7);
    :hover {
      color: rgba(27, 28, 37, 0.9);
    }
  }
`;
